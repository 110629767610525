<template>
    <div class="PriceListSelector">
        <select id="listino" placeholder="Listino" class="ms-2" v-model="listino">
            <option value="0" v-if="has_prices">EUR</option>
            <option value="1" v-if="has_prices_gbp">UK</option>
            <option value="2" v-if="has_prices_cny">CHN</option>
            <option value="3" v-if="has_prices_rub">RUS</option>
            <option value="4" v-if="has_prices_usd">USD</option>
        </select>
    </div>
</template>

<script setup lang="ts">

const { has_prices,
    has_prices_gbp,
    has_prices_cny,
    has_prices_rub,
    has_prices_usd, listino } = usePermissions();

</script>
